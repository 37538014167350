.rbc-event {
  border: 1px solid white!important;
  border-radius: 0;
  font-size: 11px;
  padding: 0;
  background: none;
  color: black;

  .rbc-event-content {

    & > .available-slot {
      background-color: #4caf50;
      height: 100%;
      padding: 2px 4px;

    }

    & > .appointment {
      background-color: #ffea00;
      height: 100%;
      padding: 2px 4px;

    }

    & > .repeated {
      background-color: #f48b1e;
      height: 100%;
      padding: 2px 4px;
    }

    & > .waiting_request {
      background-color: #7bee50;
      height: 100%;
      padding: 2px 4px;
    }

  }
}

.ant-select {
  width: 100%;
}

//
//.available-slot {
//  .rbc-event-content & {
//    background-color: red;
//
//    .rbc-addons-dnd-resizable & {
//      background-color: blue;
//
//      .rbc-event & {
//        background-color: black;
//      }
//    }
//  }
//}
